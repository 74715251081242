import { FC } from 'react'
import { MDXProvider } from '@mdx-js/react'
import { PostSection } from './PostSection'
import { PostLink } from './PostLink'

const shortcodes = {
  PostSection,
  PostLink,
}

export const Mdx: FC = ({ children }) => {
  return <MDXProvider components={shortcodes}>{children}</MDXProvider>
}
