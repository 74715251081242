import { FC } from 'react'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { getSrc, getImage } from 'gatsby-plugin-image'
import { Layout } from 'src/components/site/Layout'
import { Section } from 'src/components/parts/Section'
import { Container } from 'src/components/parts/Container'
import { Paragraph } from 'src/components/parts/Paragraph'
import { PanelGrid } from 'src/components/parts/PanelGrid'
import { Breadcrumb } from 'src/components/groups/Breadcrumb'
import { PageHeading } from 'src/components/parts/PageHeading'
import { SectionHeading } from 'src/components/parts/SectionHeading'
import { PostLink } from 'src/components/parts/PostLink'
import { TextLink } from 'src/components/parts/TextLink'
import { IconLink } from 'src/components/parts/IconLink'
import { Mdx } from 'src/components/parts/Mdx'
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import { color, size } from 'src/utils/constants'
import 'prismjs/themes/prism-tomorrow.css'
import { faTwitter, faFacebookF } from '@fortawesome/free-brands-svg-icons'

type Props = {
  data: GatsbyTypes.PostQuery
}

const PostPage: FC<Props> = ({ data }) => {
  const post = data.mdx?.frontmatter
  const body = data.mdx?.body
  const heading = post?.title
  const slug = post?.slug
  const date = post?.date
  const tag = post?.tag
  // @ts-ignore
  const ogSrc = getSrc(post?.thumbnail)
  const description = post?.description
  const url = `https://kokoniarukoto.dev/blog/${slug}/`
  const index = post?.noindex
  const breadcrumbItems = [
    {
      title: 'HOME',
      path: '/',
    },
    {
      title: 'BLOG',
      path: '/blog/',
    },
    {
      title: heading || '記事',
      path: `/blog/${slug}/`,
    },
  ]
  return (
    <Layout
      title={heading}
      description={description}
      image={ogSrc}
      ogType="article"
      ldType="Article"
      noindex={index}
    >
      <article
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <Section id="postTop">
          <Container>
            <PageHeading heading={heading} tag={tag} date={date} />
          </Container>
        </Section>
        <Breadcrumb breadcrumbItems={breadcrumbItems} />
        {body ? (
          <Mdx>
            <MDXRenderer>{body}</MDXRenderer>
          </Mdx>
        ) : null}
        <Section>
          <Container>
            <SectionHeading heading="この記事をシェア" />
            <Paragraph>
              <ul
                css={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: `0 ${size.gutter.s}rem`,
                }}
              >
                <li css={{ maxWidth: 'max-content' }}>
                  <IconLink
                    to={`https://twitter.com/share?url=${url}%0a&text=${heading}%0a&hashtags=kokoniarukoto%0a,${tag}`}
                    icon={faTwitter}
                    title="ツイッターでシェアする"
                    buttonColor={`${color.twitter}`}
                  />
                </li>
                <li>
                  <IconLink
                    to={`http://www.facebook.com/share.php?u=${url}`}
                    icon={faFacebookF}
                    title="フェイスブックでシェアする"
                    buttonColor={`${color.facebook}`}
                  />
                </li>
              </ul>
            </Paragraph>
          </Container>
        </Section>
        <Section>
          <Container>
            <SectionHeading heading="最新の記事" />
            <PanelGrid>
              {data.allMdx.edges.map(({ node }) => {
                const posts = node.frontmatter
                // @ts-ignore
                const image = getImage(posts?.thumbnail)
                return (
                  <PostLink
                    key={node.id}
                    slug={posts?.slug}
                    heading={posts?.title}
                    tag={posts?.tag}
                    image={image}
                  />
                )
              })}
            </PanelGrid>
            <Paragraph>
              <TextLink
                to="/blog/"
                text="もっと見る"
                css={{ marginRight: 'auto' }}
              />
            </Paragraph>
          </Container>
        </Section>
      </article>
    </Layout>
  )
}

export const pageQuery = graphql`
  query Post($id: String!) {
    mdx: mdx(id: { eq: $id }) {
      body
      id
      frontmatter {
        slug
        date(formatString: "YYYY-MM-DD")
        title
        description
        noindex
        tag
        thumbnail {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
    allMdx: allMdx(
      limit: 3
      sort: { order: DESC, fields: frontmatter___date }
    ) {
      edges {
        node {
          id
          frontmatter {
            thumbnail {
              childImageSharp {
                gatsbyImageData
              }
            }
            slug
            tag
            title
          }
        }
      }
    }
  }
`
export default PostPage
