import { FC } from 'react'
import { Link } from 'gatsby'
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { size, color } from 'src/utils/constants'

type Props = {
  title: string
  slug: string
}

export const PostLink: FC<Props> = ({ slug, title, ...props }) => {
  return (
    <Link to={`/blog/${slug}/`} css={rootStyle} {...props}>
      | {title} | を読む
    </Link>
  )
}

const rootStyle = css`
  display: block;
  max-width: max-content;
  position: relative;
  font-weight: ${size.weight.m};
  transition: border ${size.delay.s}s ease-out, color ${size.delay.s}s ease-out;
  border-bottom: ${size.border.s}rem dotted ${color.border};
  &[aria-current='page'] {
    color: ${color.shadow};
    border-bottom: ${size.border.s}rem dotted ${color.shadow};
  }
  &:not([aria-current='page']) {
    &:hover,
    &:focus {
      color: ${color.shadow};
      border-bottom: ${size.border.s}rem dotted ${color.shadow};
    }
  }
`
