import { FC } from 'react'
import { Section } from 'src/components/parts/Section'
import { Container } from 'src/components/parts/Container'
import { SectionHeading } from 'src/components/parts/SectionHeading'
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { color, size } from 'src/utils/constants'

type Props = {
  heading: string
}

export const PostSection: FC<Props> = ({ children, heading }) => {
  return (
    <Section>
      <Container small css={postContentStyle}>
        <SectionHeading heading={heading} />
        {children}
      </Container>
    </Section>
  )
}

const postContentStyle = css`
  & > h3 {
    font-size: ${size.font.h3}rem;
    max-width: max-content;
    margin-right: auto;
    padding: 1rem;
    background-color: ${color.gray};
    border-left: ${size.border.s}rem solid ${color.border};
  }
  & > ul {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem 0;
    padding: 1rem;
    border: ${size.border.s}rem dotted ${color.text};
    & > li {
      font-weight: ${size.weight.m};
      & > a {
        &:not([class]) {
          text-decoration: underline;
          &:hover,
          &:focus {
            text-decoration: none;
            color: ${color.shadow};
          }
        }
      }
    }
  }
  & > p {
    & > .gatsby-resp-image-wrapper {
      max-width: calc(100% - ${size.shadow.s}rem);
      & > .gatsby-resp-image-link {
        transition: box-shadow ${size.delay.s}s ease-out;
        box-shadow: ${size.shadow.s}rem ${size.shadow.s}rem ${color.shadow};
        &:hover,
        &:focus {
          box-shadow: 0rem 0rem ${color.shadow};
        }
      }
    }
    & > a {
      &:not([class]) {
        text-decoration: underline;
        &:hover,
        &:focus {
          text-decoration: none;
          color: ${color.shadow};
        }
      }
    }
  }
  & > .gatsby-highlight {
    & > pre {
      margin: 0;
      padding: 1rem;
      background: ${color.text};
      & > code {
        color: ${color.bg};
      }
    }
  }
`
